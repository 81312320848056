import React from "react";
import ListingsPage from "./ListingsPage";
import Navbar from "./Navbar";
import ClassListingHeroSection from "./ClassListingHeroSection";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import EnglishClass from "../assets/EnglishClass.webp";

const englishClasses = [
  {
    id: 1,
    title: "Beginner English Speaking classes",
    description: "English Speaking with Native speaker kids.",
    // price: "$18 per class",
    ageRange: "Group Class • Ages: 9-14",
    imageUrl: EnglishClass,
    rating: "*****",
  },
  // Add more coding class items
];

const EnglishClassListings: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <ClassListingHeroSection
        title={t("englishClasses.title")} // Use translation keys
        description={t("englishClasses.description")}
        reviews={t("englishClasses.reviews")}
      />
      <ListingsPage
        classes={englishClasses}
        // filters={[
        //   t("filters.dayOrTime"), // Translated filter
        //   t("filters.date"), // Translated filter for Date
        //   t("filters.classSize"), // Translated filter for Class size
        //   t("filters.anyPrice"), // Translated filter for Any price
        //   t("filters.age"), // Translated filter for Age
        //   t("filters.more"), // Translated filter for More
        // ]}
      />
      <Footer />
    </>
  );
};

export default EnglishClassListings;
