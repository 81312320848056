import React from "react";
import { useTranslation } from "react-i18next";
import ListingsPage from "./ListingsPage";
import Navbar from "./Navbar";
import ClassListingHeroSection from "./ClassListingHeroSection";
import Footer from "./Footer";
import dancingClass from "../assets/dancingClass.webp";

const artClasses = [
  {
    id: 1,
    title: "artClasses.listings.title", // Translation key for the title
    description: "artClasses.listings.description", // Translation key for the description
    ageRange: "artClasses.listings.ageRange", // Translation key for age range
    imageUrl: dancingClass,
    rating: "****",
  },
  // Add more art class items as needed
];

const ArtClassListings: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <ClassListingHeroSection
        title={t("artClasses.title")} // Use translation
        description={t("artClasses.description")} // Use translation
        reviews={t("artClasses.reviews")} // Use translation
      />
      <ListingsPage
        classes={artClasses.map((classItem) => ({
          ...classItem,
          title: t(classItem.title), // Translate class title
          description: t(classItem.description), // Translate class description
          ageRange: t(classItem.ageRange), // Translate age range
        }))}
        // filters={[
        //   t("filters.dayOrTime"), // Translated filter
        //   t("filters.date"), // Translated filter for Date
        //   t("filters.classSize"), // Translated filter for Class size
        //   t("filters.anyPrice"), // Translated filter for Any price
        //   t("filters.age"), // Translated filter for Age
        //   t("filters.more"), // Translated filter for More
        // ]}
      />
      <Footer />
    </>
  );
};

export default ArtClassListings;
