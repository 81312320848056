import React from "react";

interface ClassItem {
  id: number;
  title: string;
  description: string;
  // price: string;
  ageRange: string;
  imageUrl: string;
  rating: string;
}

interface ListingsPageProps {
  classes: ClassItem[];
  // filters: string[];
}

const ListingsPage: React.FC<ListingsPageProps> = ({ classes }) => {
  return (
    <div className="container mx-auto p-6">
      {/* Filter Section */}
      {/* <div className="flex flex-wrap gap-x-3 gap-y-2 mb-6">
        {filters.map((filter, index) => (
          <button
            key={index}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            {filter}
          </button>
        ))}
      </div> */}

      {/* Class Listings */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {classes.map((classItem) => (
          <div key={classItem.id} className="bg-white shadow-md rounded-lg p-4">
            <img
              src={classItem.imageUrl}
              alt={classItem.title}
              className="rounded-t-lg mb-4"
            />
            <h2 className="text-xl font-semibold text-gray-800">
              {classItem.title}
            </h2>
            <p className="text-gray-600">{classItem.description}</p>
            <div className="text-lg font-bold text-gray-800 mt-4">
              {/* {classItem.price} */}
              {classItem.rating}
            </div>
            <div className="text-sm text-gray-500">{classItem.ageRange}</div>
            <button className="bg-orange-500 text-white px-4 py-2 rounded-lg mt-4">
              Enroll Now
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListingsPage;
