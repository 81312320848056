import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next"; // Im

interface OffcanvasProps {
  isOffcanvas: boolean;
  setIsOffcanvas: React.Dispatch<React.SetStateAction<boolean>>;
}

const Offcanvas: React.FC<OffcanvasProps> = ({
  isOffcanvas,
  setIsOffcanvas,
}) => {
  const [isSubjectsDropdownOpen, setIsSubjectsDropdownOpen] = useState(false);
  const [isClassesDropdownOpen, setIsClassesDropdownOpen] = useState(false);

  const { t, i18n } = useTranslation(); // Destructure t and i18n from useTranslation

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng); // Save the selected language to local storage
  };

  return (
    <div
      className={`fixed inset-0 z-50 transform transition-transform duration-300 ease-in-out ${
        isOffcanvas ? "translate-x-0" : "translate-x-full"
      } flex justify-end bg-[#0000004a]`}
      onClick={() => setIsOffcanvas(false)}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-white w-64 h-full shadow-xl transition-transform  ease-in-out duration-300 transform p-6 relative ${
          isOffcanvas ? "translate-x-0" : "translate-x-full"
        } overflow-y-scroll scroll-none`}
      >
        {/* Close Button */}
        <button
          onClick={() => setIsOffcanvas(false)}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none transition-colors"
        >
          ✕
        </button>

        {/* Navigation Links */}
        <ul className="space-y-6 mt-8 text-gray-900">
          {/* Fanlar Dropdown */}
          <li>
            <div className="relative">
              <div
                onClick={() =>
                  setIsSubjectsDropdownOpen(!isSubjectsDropdownOpen)
                }
                className="cursor-pointer text-lg font-semibold text-gray-700 hover:text-orange-500 focus:outline-none transition-colors"
              >
                {t("navbar.subjects")} <span className="ml-1">▼</span>
              </div>
              {isSubjectsDropdownOpen && (
                <div className="mt-2 bg-white shadow-lg rounded-lg py-2 space-y-2">
                  <Link
                    to="/math-class-listings"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded transition-colors"
                    onClick={() => setIsOffcanvas(false)}
                  >
                    {t("dropdown.math")}
                  </Link>
                  <Link
                    to="/english-class-listings"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded transition-colors"
                    onClick={() => setIsOffcanvas(false)}
                  >
                    {t("dropdown.english")}
                  </Link>
                  <Link
                    to="/coding-class-listings"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded transition-colors"
                    onClick={() => setIsOffcanvas(false)}
                  >
                    {t("dropdown.coding")}
                  </Link>
                  <Link
                    to="/art-class-listings"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded transition-colors"
                    onClick={() => setIsOffcanvas(false)}
                  >
                    {t("dropdown.art")}
                  </Link>
                </div>
              )}
            </div>
          </li>

          {/* Darslar Dropdown */}
          {/* <li>
            <div className="relative">
              <div
                onClick={() => setIsClassesDropdownOpen(!isClassesDropdownOpen)}
                className="cursor-pointer text-lg font-semibold text-gray-700 hover:text-orange-500 focus:outline-none transition-colors"
              >
                {t("navbar.classes")} <span className="ml-1">▼</span>
              </div>
              {isClassesDropdownOpen && (
                <div className="mt-2 bg-white shadow-lg rounded-lg py-2 space-y-2">
                  <Link
                    to="#"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded transition-colors"
                    onClick={() => setIsOffcanvas(false)}
                  >
                    {t("dropdown.tutoring")}
                  </Link>
                  <Link
                    to="#"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded transition-colors"
                    onClick={() => setIsOffcanvas(false)}
                  >
                    {t("dropdown.fullCourse")}
                  </Link>
                  <Link
                    to="#"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded transition-colors"
                    onClick={() => setIsOffcanvas(false)}
                  >
                    {t("dropdown.collegePrep")}
                  </Link>
                  <Link
                    to="#"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded transition-colors"
                    onClick={() => setIsOffcanvas(false)}
                  >
                    {t("dropdown.afterschool")}
                  </Link>
                  <Link
                    to="#"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded transition-colors"
                    onClick={() => setIsOffcanvas(false)}
                  >
                    {t("dropdown.clubs")}
                  </Link>
                  <Link
                    to="#"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded transition-colors"
                    onClick={() => setIsOffcanvas(false)}
                  >
                    {t("dropdown.crashCourses")}
                  </Link>
                </div>
              )}
            </div>
          </li> */}

          {/* Other Links */}
          <li>
            <Link
              to="/pricing"
              className="text-lg font-semibold text-gray-700 hover:text-orange-500 transition-colors"
              onClick={() => setIsOffcanvas(false)}
            >
              {t("navbar.pricing")}
            </Link>
          </li>
          <li>
            <a
              href="/how-it-works"
              className="text-lg font-semibold text-gray-700 hover:text-orange-500 transition-colors"
              onClick={() => setIsOffcanvas(false)}
            >
              {t("navbar.howItWorks")}
            </a>
          </li>
          <li>
            <a
              href="/contact-us"
              className="text-lg font-semibold text-gray-700 hover:text-orange-500 transition-colors"
              onClick={() => setIsOffcanvas(false)}
            >
              {t("navbar.contactUs")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Offcanvas;
