import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axiosInstance from "../common/api/axios";

interface ContactDetailsModalProps {
  closeModal: () => void;
}

const MySwal = withReactContent(Swal);

const ContactDetailsModal: React.FC<ContactDetailsModalProps> = ({
  closeModal,
}) => {
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleConfirm = async () => {
    if (!email || !phoneNumber || !name) {
      MySwal.fire({
        icon: "error",
        title: "Input Error",
        text: "Please fill in all fields correctly.",
      });
      return;
    }

    setLoading(true);

    try {
      const response = await axiosInstance.post("/mail", {
        name,
        email,
        phoneNumber,
        message: "From Contact Modal",
      });

      if (response.status === 201) {
        MySwal.fire({
          icon: "success",
          title: "Success",
          text: "Your contact details have been submitted successfully!",
        });
        closeModal(); // Close the modal after success
      } else {
        throw new Error("Failed to submit contact details.");
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "An error occurred while submitting your contact details.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-4 text-gray-900">
          Please enter your contact details!
        </h2>

        <div className="mb-4">
          <label className="block text-gray-700">Full name:</label>
          <input
            type="text"
            className="w-full px-4 py-2 border rounded-lg text-gray-900"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        {/* Email Input */}
        <div className="mb-4">
          <label className="block text-gray-700">Email:</label>
          <input
            type="email"
            className="w-full px-4 py-2 border rounded-lg text-gray-900"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        {/* Phone Number Input */}
        <div className="mb-4">
          <label className="block text-gray-700">Phone:</label>
          <input
            type="tel"
            className="w-full px-4 py-2 border rounded-lg text-gray-900"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>

        {/* Confirm Button */}
        <div className="mt-4 flex justify-end space-x-4">
          <button
            onClick={closeModal}
            className="px-4 py-2 border rounded-lg text-red-500"
            disabled={loading}
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsModal;
