import React from "react";
import { useTranslation } from "react-i18next";
import ListingsPage from "./ListingsPage";
import Navbar from "./Navbar";
import ClassListingHeroSection from "./ClassListingHeroSection";
import Footer from "./Footer";
import codingForKids from "../assets/coding4kids.png";

const codingClasses = [
  {
    id: 1,
    title: "codingClasses.listings.title", // Translation key for title
    description: "codingClasses.listings.description", // Translation key for description
    ageRange: "codingClasses.listings.ageRange", // Translation key for age range
    imageUrl: codingForKids,
    rating: "*****",
  },
  // Add more coding class items as needed
];

const CodingClassListings: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <ClassListingHeroSection
        title={t("codingClasses.title")} // Use translation
        description={t("codingClasses.description")} // Use translation
        reviews={t("codingClasses.reviews")} // Use translation
      />
      <ListingsPage
        classes={codingClasses.map((classItem) => ({
          ...classItem,
          title: t(classItem.title), // Translate class title
          description: t(classItem.description), // Translate class description
          ageRange: t(classItem.ageRange), // Translate age range
        }))}
        // filters={[
        //   t("filters.dayOrTime"), // Translated filter
        //   t("filters.date"), // Translated filter for Date
        //   t("filters.classSize"), // Translated filter for Class size
        //   t("filters.anyPrice"), // Translated filter for Any price
        //   t("filters.age"), // Translated filter for Age
        //   t("filters.more"), // Translated filter for More
        // ]}
      />
      <Footer />
    </>
  );
};

export default CodingClassListings;
