import React, { useState } from "react";
import { REGISTER_PATH } from "../common/api"; // Adjust the path to your index.ts
import axiosInstance from "../common/api/axios";
import { Roles } from "./../common/types/role";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface RegisterResponse {
  message: string;
}

const MySwal = withReactContent(Swal);

const Register: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setConfirmPassword] = useState("");
  const [role, setRole] = useState(Roles[1]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== password_confirmation) {
      alert("Passwords don't match");
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      const new__data = {
        email,
        password,
        role,
        status: "active",
      };
      const response = await axiosInstance.post<RegisterResponse>(
        REGISTER_PATH,
        new__data
      );

      setTimeout(() => {
        Swal.fire({
          icon: "success",
          title: "Registration successful",
          text: response.data.message, // No error here
          confirmButtonText: `<a href="/login">Go to Login page</a>`,
        });
      }, 1000);
    } catch (error: any) {
      console.log(error);
      setErrorMessage(
        error?.response?.data?.message ||
          "Registration failed. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-semibold text-center text-blue-600 mb-6">
          Register
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {errorMessage && (
            <div className="text-red-500 text-center mb-4">{errorMessage}</div>
          )}
          <div>
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-200"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              name="email"
            />
          </div>
          <div>
            <label className="block text-gray-700">Password</label>
            <input
              type="password"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-200"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              name="password"
            />
          </div>
          <div>
            <label className="block text-gray-700">Confirm Password</label>
            <input
              type="password"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-200"
              value={password_confirmation}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              name="password_confirmation"
            />
          </div>
          <div>
            <label className="block text-gray-700">Select Role</label>
            <div className="flex items-center space-x-4">
              <div className="flex flex-col space-y-4">
                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    name="role"
                    value={Roles[1]} // Student
                    checked={role === Roles[1]}
                    onChange={() => setRole(Roles[1])}
                    className="form-radio h-5 w-5 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-gray-800 font-medium">
                    Student
                  </span>
                </label>

                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    name="role"
                    value={Roles[0]} // Teacher
                    checked={role === Roles[0]}
                    onChange={() => setRole(Roles[0])}
                    className="form-radio h-5 w-5 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-gray-800 font-medium">
                    Teacher
                  </span>
                </label>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700"
            disabled={loading}
          >
            {loading ? "Registering..." : "Register"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
