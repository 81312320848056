import React from "react";

interface HeroSectionProps {
  title: string;
  description: string;
  reviews: string;
}

const ClassListingHeroSection: React.FC<HeroSectionProps> = ({
  title,
  description,
  reviews,
}) => {
  return (
    <div className="bg-purple-100 py-12 px-6">
      <div className="max-w-screen-2xl mx-auto flex flex-col md:flex-row items-center">
        <div className="md:w-1/2">
          <h1 className="text-4xl font-bold text-gray-800">{title}</h1>
          <ul className="mt-4 space-y-2">
            <li className="flex items-center text-gray-700">
              <span role="img" aria-label="Video">
                🎥
              </span>{" "}
              Engaging live video chat classes
            </li>
            <li className="flex items-center text-gray-700">
              <span role="img" aria-label="Teacher">
                🎖
              </span>{" "}
              Vetted and passionate teachers
            </li>
            <li className="flex items-center text-gray-700">
              <span role="img" aria-label="Confidence">
                🚀
              </span>{" "}
              Build confidence through progress
            </li>
          </ul>
          <div className="mt-4">
            <span className="font-bold text-green-600">Excellent</span>
            <span className="text-green-600"> ★★★★★ </span>
            <span className="text-gray-600">{reviews}</span>
          </div>
          <button className="bg-orange-500 text-white px-4 py-2 rounded-lg mt-4">
            Enroll Now
          </button>
          {/* <div className="mt-6">
            <label className="block text-gray-600">Learner's age</label>
            <div className="flex space-x-4 mt-2">
              <label className="flex items-center">
                <input type="radio" name="age" className="form-radio" /> 3-5
              </label>
              <label className="flex items-center">
                <input type="radio" name="age" className="form-radio" /> 6-9
              </label>
              <label className="flex items-center">
                <input type="radio" name="age" className="form-radio" /> 10-13
              </label>
              <label className="flex items-center">
                <input type="radio" name="age" className="form-radio" /> 14-18
              </label>
            </div>
          </div> */}
        </div>
        <div className="md:w-1/3 mt-8 md:mt-0">
          <img
            src="./girl-with-laptop.webp"
            alt="Hero"
            className="rounded-lg shadow-md h-80"
          />
        </div>
      </div>
    </div>
  );
};

export default ClassListingHeroSection;
