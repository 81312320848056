import React from "react";
import ListingsPage from "./ListingsPage";
import Navbar from "./Navbar";
import ClassListingHeroSection from "./ClassListingHeroSection";
import Footer from "./Footer";

import quranLesson from "../assets/Quran_learning_kid.webp";
import { t } from "i18next";

const quranClasses = [
  {
    id: 1,
    title: "Tajweed - Qaida",
    description: "Learn the fundamentals of Quran.",
    // price: "$25 per class",
    ageRange: "Group Class • Ages: 12-15",
    imageUrl: quranLesson,
    rating: "*****",
  },
  // Add more math class items
];

const QuranClassListings: React.FC = () => {
  return (
    <>
      <Navbar />
      <ClassListingHeroSection
        title={t("quranClasses.title")}
        description={"Learn the fundamentals of Quran."}
        reviews={"(3,104 reviews on Trustpilot)"}
      />
      <ListingsPage
        classes={quranClasses}
        // filters={[
        //   "Day or time",
        //   "Date",
        //   "Class size",
        //   "Any price",
        //   "Age",
        //   "More",
        // ]}
      />
      <Footer />
    </>
  );
};

export default QuranClassListings;
