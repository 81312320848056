import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en/translation.json";
import uzTranslation from "./locales/uz/translation.json";

const savedLanguage = localStorage.getItem("language") || "en"; // Get saved language or default to "en"

i18n.use(initReactI18next).init(
  {
    resources: {
      en: { translation: enTranslation },
      uz: { translation: uzTranslation },
    },
    lng: savedLanguage, // Use the saved language
    fallbackLng: "en", // fallback language
    interpolation: {
      escapeValue: false, // react already escapes values
    },
  },
  (error, t) => {
    if (error) {
      console.error("i18next initialization failed:", error);
    } else {
      console.log("i18next initialized successfully");
    }
  }
);

export default i18n;
