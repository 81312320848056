import "./App.css";
import React, { createContext, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import PricingPlans from "./components/PricingPlans";
import Login from "./components/Login";
import Register from "./components/Register";
import ForgotPassword from "./components/ForgotPassword";
import HowItWorks from "./components/HowItWorks";
import ContactUsPage from "./pages/ContactUsPage";
import QuranClassListings from "./components/QuranClassListings";
import CodingClassListings from "./components/CodingClassListings";
import EnglishClassListings from "./components/EnglishClassListings";
import FreeTrialClass from "./components/FreeTrialClass";
import ArtClassListings from "./components/ArtClassListings";
import Dashboard from "./components/Dashboard";
import Classroom from "./components/Classroom";
import ClassDetails from "./components/ClassDetails";
import PrivateRoute from "./components/PrivateRoute"; // Import the PrivateRoute component
import { IUser } from "./common/types/user";
import { ACCESS_TOKEN } from "./common/api";
import Classes from "./components/Classes";
import AddClass from "./components/teacher/AddClass";
import EditClass from "./components/teacher/EditClass";
import axiosInstance from "./common/api/axios";
import AOS from "aos";
import "aos/dist/aos.css";
import Loading from "./components/Loading";

export const Context = createContext<any>(null);

const App: React.FC = () => {
  const [user, setUser] = useState<IUser | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1200, // duration of the animation
      once: true, // whether animation should happen only once - while scrolling down
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
      const checkAccess = async () => {
        try {
          const response = await axiosInstance.get<{ user: IUser }>(
            "/user/login/access",
            {
              headers: { Authorization: `Bearer ${token}` }, // Set the token in headers
            }
          );
          setUser(response.data.user);
        } catch (err: any) {
          console.error(err?.message);
        } finally {
          setIsAuthenticating(false);
        }
      };
      checkAccess();
    } else {
      setIsAuthenticating(false);
    }
  }, []);

  if (isAuthenticating) return <Loading />;

  return (
    <Context.Provider value={{ user, setUser }}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/pricing" element={<PricingPlans />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route
            path="/quran-class-listings"
            element={<QuranClassListings />}
          />
          <Route
            path="/english-class-listings"
            element={<EnglishClassListings />}
          />
          <Route
            path="/coding-class-listings"
            element={<CodingClassListings />}
          />
          <Route path="/art-class-listings" element={<ArtClassListings />} />
          <Route path="/free-trial" element={<FreeTrialClass />} />

          {/* Private Routes */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute isAuthenticated={!!user}>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/classroom/:id/:type"
            element={
              <PrivateRoute isAuthenticated={!!user}>
                <Classroom />
              </PrivateRoute>
            }
          />
          <Route
            path="/classes"
            element={
              <PrivateRoute isAuthenticated={!!user}>
                <Classes />
              </PrivateRoute>
            }
          />
          <Route
            path="/classdetails/:id/:type"
            element={
              <PrivateRoute isAuthenticated={!!user}>
                <ClassDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-class-group"
            element={
              <PrivateRoute isAuthenticated={!!user}>
                <AddClass />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit/:id/:state"
            element={
              <PrivateRoute isAuthenticated={!!user}>
                <EditClass />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </Context.Provider>
  );
};

export default App;
